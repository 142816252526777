.excel-extractor {
    max-width: 600px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-family: Arial, sans-serif;
}

h2 {
    font-size: 24px;
    text-align: center;
}

.file-upload {
    display: block;
    width: 200px;
    padding: 10px;
    text-align: center;
    background-color: #007bff;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.file-upload input[type="file"] {
    display: none;
}

.file-upload:hover {
    background-color: #0056b3;
}

.extracted-products {

}

.extracted-products h3 {
    font-size: 20px;
}

.extracted-products ul {
    list-style-type: none;
    padding: 0;
}

.extracted-products li {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;

}
