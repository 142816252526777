.chart-popup {
    position: absolute;
    top: 100%; /* Adjust as needed */
    left: 280px;
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 10;
}
.statistics {
    cursor: pointer;
}
.statistics:hover {
    background: orange;
}